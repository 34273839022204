import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'
//进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


const routePush = Router.prototype.push
Router.prototype.push = function push(to) {
    return routePush.call(this, to).catch(err =>{})
}
const routeReplace = Router.prototype.replace
Router.prototype.replace = function replace(to) {
    return routeReplace.call(this, to).catch(err =>{})
}

Vue.use(Router)


const routes = [
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '/',
        redirect: '/pets-home-page', // 重定向:重新指向其它path,会改变网址
    },
    {
        path: '/account',
        redirect: '/account/index', // 重定向:重新指向其它path,会改变网址
    },
    {
        path: '/pets-home-page',
        name: 'pets-home-page',
        hidden: true,
        component: () => import('../views/Layout/index.vue'),
        meta: { title: 'index', icon: "iconfont icon-wode2" },
        children: [
            {
                path: '/login',
                name: 'petsH-login-page',
                component: () => import('@/views/Login/login.vue'),
                hidden: true
            },
            {
                path: '/petsH-register-page',
                name: 'petsH-register-page',
                component: () => import('@/views/Login/register.vue'),
                hidden: true
            },
            {
                path: '/pets-home-page',
                name: 'pets-home-page',
                hidden: true,
                component: () => import('../views/HomePage/index.vue'),
                meta: { title: 'pets-home-page', icon: "iconfont icon-wode2" }
            },
            {
                path: '/cart',
                name: 'shopping-cart',
                hidden: true,
                component: () => import('@/views/Cart/index.vue'),
                meta: { title: 'shopping-cart', icon: "iconfont icon-wode2" }
            },
            {
                path: '/contact-us',
                name: 'contact-us',
                hidden: true,
                component: () => import('../views/ContactUs/index.vue'),
                meta: { title: 'contact-us', icon: "iconfont icon-wode2" }
            },
            {
                path: '/about-us',
                name: 'about-us',
                hidden: true,
                component: () => import('../views/AboutUs/index.vue'),
                meta: { title: 'about-us', icon: "iconfont icon-wode2" }
            },
            {
                path: '/retail-products',
                name: 'retail-products',
                hidden: true,
                component: () => import('../views/Category/index.vue'),
                meta: { title: 'retail-products', icon: "iconfont icon-wode2" }
            },
            {
                path: '/goods-detail',
                name: 'retail-detail',
                hidden: true,
                component: () => import('../components/goodsDetail.vue'),
                meta: { title: 'goods-detail', icon: "iconfont icon-wode2" }
            },
            {
                path: '/search-goods-banner',
                name: 'search-goods-banner',
                hidden: true,
                component: () => import('../components/searchGoodsBanner.vue'),
                meta: { title: 'search-goods-banner', icon: "iconfont icon-wode2" }
            },
            {
                path: '/detailBanner',
                name: 'detailBanner',
                hidden: true,
                component: () => import('../components/detailBanner.vue'),
                meta: { title: 'detailBanner', icon: "iconfont icon-wode2" }
            },
            {
                path: '/order-detail',
                name: 'order-detail',
                hidden: true,
                component: () => import('../components/orderDetail.vue'),
                meta: { title: 'order-detail', icon: "iconfont icon-wode2" }
            },
            {
                path: '/checkout',
                name: 'checkout',
                hidden: true,
                component: () => import('../components/checkout.vue'),
                meta: { title: 'checkout', icon: "iconfont icon-wode2" }
            },
            {
                path: '/view-all-goods',
                name: 'view-all-goods',
                hidden: true,
                component: () => import('../components/searchAllGoods.vue'),
                meta: { title: 'view-all-goods', icon: "iconfont icon-wode2" }
            },
            {
                path: '/account',
                name: 'account',
                hidden: true,
                component: () => import('../views/myAccount/index.vue'),
                meta: { title: 'account', icon: "iconfont icon-wode2" },
                children: [
                    {
                        path: '/account/index',
                        name: 'orderList',
                        hidden: true,
                        component: () => import('../views/myAccount/account.vue'),
                        meta: { title: 'orderList', icon: "iconfont icon-wode2" }
                    },
                    {
                        path: '/order-list',
                        name: 'order-list',
                        hidden: true,
                        component: () => import('../views/myAccount/orders.vue'),
                        meta: { title: 'orderList', icon: "iconfont icon-wode2" }
                    },
                    {
                        path: '/account/myDetail',
                        name: 'myDetail',
                        hidden: true,
                        component: () => import('../views/myAccount/myDeatil.vue'),
                        meta: { title: 'myDetail', icon: "iconfont icon-wode2" }
                    },
                ]
            },
        ]
    },



]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.VUE_APP_BASE_API,
    routes
})
NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 500,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
    next();
    NProgress.start();
});
router.afterEach(() => {
    NProgress.done()
})
export default router;

