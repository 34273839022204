import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        cartList: [],
        redirectPath: ''

    },
    //状态计算
    getters: {
    },
    mutations: {
        saveCartData(state, val) {
            state.cartList = val

        },
        setRedirectPath(state, path) {
          state.redirectPath = path;
        },
        clearRedirectPath(state) {
          state.redirectPath = '';
        }
    },
    actions: {
        setRedirectPath({ commit }, path) {
            commit('setRedirectPath', path);
        },
        clearRedirectPath({ commit }) {
            commit('clearRedirectPath');
        }    
    },
    modules: {

    }

})