<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @font-face {
    font-family: 'HarmonyOS_Sans';
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: 'HarmonyOS_Sans', sans-serif;
} */
html,
body,
#app {
  width: 100%;
  height: 100%;
  font-family: Bahnschrift, serif;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
</style>
