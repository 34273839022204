import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from "./router"
import store from './store/index'
// 引入elementui 样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css';
import "../src/assets/font_icon/iconfont.css";
import '../src/assets/font_icon/iconfont';
import "../src/assets/new_font_icon/iconfont.css";
import '../src/assets/new_font_icon/iconfont';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import VueWechatTitle from 'vue-wechat-title' //可以动态修改浏览器标题的插件
Vue.use(VueWechatTitle);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(router);
new Vue({
  render: h => h(App),
  router, store
}).$mount('#app')
